import React from "react";
import "./BestSellersSection.css"; // This is the CSS file where your styles will live
import { EcommerceCard } from "../common/ecommerceCard";

const ProductsSection = () => {
  const products = [
    {
      productName: "Rewind",
      productDescription:
        "Embrace the charm of yesteryears with Rewind, where classic design meets modern eye care.",
      productPrice: "29.99",
      imageUrls: [
        "images/glasses/rewind/variant1.png",
        "images/glasses/rewind/variant2.png",
        "images/glasses/rewind/variant3.png",
        "images/glasses/rewind/variant4.png",
      ],
      productLink: "https://shop.bluckers.com/products/testing",
      navButtonColors: ["black/brown", "black", "transparent", "gray"],
    },
    {
      productName: "The Element",
      productDescription:
        "Strips back to the basics, focusing on essential quality and minimalist aesthetics.",
      productPrice: "29.99",
      productLink: "https://shop.bluckers.com/products/testing",
      imageUrls: [
        "images/glasses/element/variant1.png",
        "images/glasses/element/variant2.png",
        "images/glasses/element/variant3.png",
        "images/glasses/element/variant4.png",
      ],
      navButtonColors: ["transparent", "black", "gray", "orange/brown"],
    },
    {
      productName: "The Vortex",
      productDescription:
        "Vortex embraces the future with glasses that blend tech-savvy features with sleek design.",
      productPrice: "29.99",
      imageUrls: [
        "images/glasses/vortex/variant1.png",
        "images/glasses/vortex/variant2.png",
        "images/glasses/vortex/variant3.png",
        "images/glasses/vortex/variant4.png",
      ],
      productLink: "https://shop.bluckers.com/products/testing",
      navButtonColors: [
        "black/red",
        "black",
        "brown/orange",
        "black/brown/orange",
      ],
    },
  ];

  return (
    <div>
      <h2 className="section-title">Shop Best Sellers</h2>
      <div className="products-container">
        {products.map((product, index) => (
          <EcommerceCard key={index} {...product} />
        ))}
      </div>
    </div>
  );
};

export default ProductsSection;
