import React from "react";
import "./featureSection.css"; // Make sure the path to your CSS file is correct

const Feature = ({ title, position, description, imageUrl, reverse }) => {
  const featureClass = reverse ? "feature feature-reverse" : "feature";

  return (
    <div className={featureClass + ` feature-position-${position}`}>
      {/* Use an img tag instead of a div with a background image */}
      {imageUrl && <img src={imageUrl} alt={title} className="feature-image" />}
      <div className="feature-content">
        <h2 className="feature-title">{title}</h2>
        <p className="feature-description">{description}</p>
      </div>
    </div>
  );
};

export default Feature;
