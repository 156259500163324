import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Carousel,
} from "@material-tailwind/react";

export function EcommerceCard({
  imageUrls,
  productName,
  productPrice,
  productDescription,
  productLink,
  navButtonColors = [], // An array of color values or multi-color strings like "red/blue/yellow"
}) {
  const isMultipleImages = imageUrls.length > 1;

  // Function to handle multi-color navigation buttons
  const navButtonStyle = (index, activeIndex) => {
    const defaultColor = "black"; // Default color if none provided
    const colorInput = navButtonColors[index] || defaultColor; // Use provided color or default
    const isActive = activeIndex === index;
    const colors = colorInput.split("/"); // Split the input into individual colors

    // Determine the gradient based on the number of specified colors
    const gradient =
      colors.length > 1
        ? `linear-gradient(to right, ${colors
            .map(
              (color, i) =>
                `${color} ${(i / colors.length) * 100}%, ${color} ${
                  ((i + 1) / colors.length) * 100
                }%`
            )
            .join(", ")})`
        : colorInput !== "transparent"
        ? colorInput
        : "transparent";

    // Style for transparent color or gradient
    const colorStyle = {
      background: gradient,
      border: colorInput === "transparent" ? "2px solid black" : "none",
    };

    return {
      cursor: "pointer",
      height: "8px", // Button height
      width: isActive ? "48px" : "24px", // Width for active/inactive state
      transition: "all 0.3s ease",
      borderRadius: "9999px", // Fully rounded ends for pill shape
      ...colorStyle, // Apply the color, gradient, or transparent style
    };
  };

  return (
    <Card className="w-96">
      <CardHeader shadow={false} floated={false} className="h-96">
        {isMultipleImages ? (
          <Carousel
            transition={{ duration: 1 }}
            className="rounded-xl"
            navigation={({ setActiveIndex, activeIndex, length }) => (
              <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-4">
                {" "}
                {/* Increased gap for larger buttons */}
                {new Array(length).fill("").map((_, i) => (
                  <span
                    key={i}
                    style={navButtonStyle(i, activeIndex)}
                    onClick={() => setActiveIndex(i)}
                  />
                ))}
              </div>
            )}
            prevArrow={() => <div></div>}
            nextArrow={() => <div></div>}
          >
            {imageUrls.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={`${productName} ${index + 1}`}
                className="h-full w-full object-cover"
              />
            ))}
          </Carousel>
        ) : (
          <img
            src={imageUrls[0]}
            alt={`${productName}`}
            className="h-full w-full object-cover"
          />
        )}
      </CardHeader>
      <CardBody>
        <div className="mb-2 flex items-center justify-between">
          <Typography color="blue-gray" className="font-medium">
            {productName}
          </Typography>
          <Typography color="blue-gray" className="font-medium">
            ${productPrice}
          </Typography>
        </div>
        <Typography
          variant="small"
          color="gray"
          className="font-normal opacity-75"
        >
          {productDescription}
        </Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <a href={productLink}>
          <Button
            ripple={false}
            fullWidth={true}
            className="bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
          >
            BUY
          </Button>
        </a>
      </CardFooter>
    </Card>
  );
}
