import React from "react";
import "./faqsSection.css";
import Faq from "react-faq-component";

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      title: "What are blue light blocking glasses, and how do they work?",
      content:
        "Blue light blocking glasses are designed with lenses that filter out the blue light emitted from digital screens, which can reduce eye strain and support better sleep. By wearing Bluckers, you can enjoy your screen time with less discomfort and better overall eye health.",
    },
    {
      title: "Can wearing Bluckers glasses improve my sleep?",
      content:
        "Absolutely! Bluckers glasses help filter out blue light that can interfere with circadian rhythms, potentially improving sleep quality and making it easier to fall asleep after evening screen use.",
    },
    {
      title: "Will the glasses affect how I see colors on my screen?",
      content:
        "Bluckers glasses are crafted to minimize color distortion while blocking harmful blue light, ensuring you can enjoy true-to-life colors on your screen.",
    },
    {
      title: "Can I get prescription lenses with blue light filtering?",
      content:
        "Yes, you can. Bluckers offers blue light filtering on a variety of prescription lenses, allowing you to protect your eyes without sacrificing vision clarity.",
    },
    {
      title: "Are Bluckers glasses suitable for children?",
      content:
        "Definitely. We provide a range of blue light blocking glasses specifically designed for children, to protect their eyes during crucial developmental years.",
    },
    {
      title: "How should I clean and care for my Bluckers glasses?",
      content:
        "To maintain the quality of your Bluckers glasses, clean them with a soft, microfiber cloth and keep them in their case when not in use. Avoid using household cleaning products which can damage the lens coatings.",
    },
    {
      title: "What is the return policy for Bluckers glasses?",
      content:
        "We offer a 15-day return policy for all non-prescription glasses. If you're not completely satisfied with your purchase, you can return them for a full refund. We want you to feel confident and comfortable with your Bluckers glasses. Please note that for prescription glasses, returns are available only in case of a defect in the product.",
    },
    {
      title: "How do blue light glasses benefit my daily routine?",
      content:
        "By reducing eye strain and potential headaches associated with prolonged screen use, Bluckers glasses help maintain your productivity and focus throughout the day.",
    },
    {
      title: "Are there different styles and frames to choose from?",
      content:
        "Indeed, Bluckers offers a diverse range of styles and frames to suit every face shape and personal preference, ensuring both comfort and fashion.",
    },
    {
      title: "How often should I wear my blue light blocking glasses?",
      content:
        "For optimal protection, wear your Bluckers glasses whenever you're using digital devices, especially during hours of prolonged exposure or when using devices before bedtime.",
    },
  ],
};

const styles = {
  bgColor: "white",
  titleTextColor: "black",
  rowTitleColor: "black",
  rowContentColor: "black",
  arrowColor: "#ff6c00",
};

const config = {
  animate: true,
  arrowIcon: "V",
  tabFocus: true,
};

const FaqsSection = () => (
  <div id="faqs" className="faqs">
    <Faq data={data} styles={styles} config={config} />
  </div>
);

export default FaqsSection;
