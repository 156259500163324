import React, { useState, useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Sidebar } from "./SideBar";
import StickyNavbar from "react-sticky-navbar";
import { ReactComponent as Logo } from "../svgs/logo.svg";
import { ReactComponent as LogoWhite } from "../svgs/logo-white.svg";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import "./Navbar.css";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    document.body.classList.remove("overflow-hidden");
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
    document.body.classList.add("overflow-hidden");
  };

  useEffect(() => {
    const onScroll = () => {
      const scrollPos =
        window.scrollY ||
        window.scrollTop ||
        document.getElementsByTagName("html")[0].scrollTop;
      setIsTop(scrollPos < 1);
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div>
      <Sidebar isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} />
      <StickyNavbar
        classNames={{
          scrollTop: "nav-bar-ontop",
        }}
        stickyBackground="lightgray"
        zIndex={1000}
      >
        <button className="burger-button" onClick={openDrawer}>
          <AiOutlineMenu />
        </button>
        <div className="navbar-buttons">
          <a href="https://bluckers.com">
            <button>Home</button>
          </a>
          <a href="https://shop.bluckers.com/collections/all">
            <button>Catalog</button>
          </a>
          <a href="https://shop.bluckers.com/pages/contact-us">
            <button>Contact Us</button>
          </a>
        </div>
        {isTop ? (
          <LogoWhite className="navbar-logo" />
        ) : (
          <Logo className="navbar-logo" />
        )}
        <div className="navbar-right">
          <a href="https://shop.bluckers.com/cart">
            <ShoppingBagIcon className="cart-icon h-7 w-7" />
          </a>{" "}
        </div>
      </StickyNavbar>
    </div>
  );
};

export default Navbar;
