import React from "react";
import {
  // Typography,
  List,
  ListItem,
  ListItemPrefix,
  // Accordion,
  // AccordionHeader,
  // AccordionBody,
  Drawer,
  Card,
} from "@material-tailwind/react";
import {
  // InboxIcon,
  XMarkIcon,
  HomeIcon,
  PhoneIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/solid";
// import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

export function Sidebar({ isDrawerOpen, closeDrawer }) {
  // const [open, setOpen] = React.useState(0);

  // const handleOpen = (value) => {
  //   setOpen(open === value ? 0 : value);
  // };

  return (
    <>
      <Drawer open={isDrawerOpen} onClose={closeDrawer} size={1000}>
        <Card
          color="transparent"
          shadow={false}
          className="h-[calc(100vh-2rem)] w-full p-4"
        >
          <div className="mb-2 flex items-center gap-4 p-4">
            <button onClick={closeDrawer}>
              <XMarkIcon className="h-8 w-8" />
            </button>{" "}
            <img src="/images/logo.svg" alt="brand" className="h-20 w-20" />
          </div>

          <List>
            <ListItem>
              <ListItemPrefix>
                <HomeIcon className="h-5 w-5" />
              </ListItemPrefix>
              Home
            </ListItem>

            <ListItem>
              <ListItemPrefix>
                <ShoppingBagIcon className="h-5 w-5" />
              </ListItemPrefix>
              Catalog
            </ListItem>

            <ListItem>
              <ListItemPrefix>
                <PhoneIcon className="h-5 w-5" />
              </ListItemPrefix>
              Contact us
            </ListItem>

            {/* <Accordion
              open={open === 1}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${
                    open === 1 ? "rotate-180" : ""
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 1}>
                <AccordionHeader
                  onClick={() => handleOpen(1)}
                  className="border-b-0 p-3"
                >
                  <ListItemPrefix>
                    <PresentationChartBarIcon className="h-5 w-5" />
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal">
                    Dashboard
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <List className="p-0">
                  <ListItem>
                    <ListItemPrefix>
                      <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Projects
                  </ListItem>
                </List>
              </AccordionBody>
            </Accordion> */}

            {/* <hr className="my-2 border-blue-gray-50" /> */}
          </List>
        </Card>
      </Drawer>
    </>
  );
}
