// import React, { useState, useEffect } from "react";

import "./App.css";
import Navbar from "./components/common/navbar";
import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeatureSection";
import FaqsSection from "./components/FaqsSection";
import SignUpSection from "./components/SignUpSection";
import WebFooter from "./components/common/footer";
import ProductsSection from "./components/BestSellersSection";
// import DiscountModal from "./components/DiscountModal";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function App() {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   const isDevelopment = window.location.hostname === "localhost";

  //   const hasSeenModal = localStorage.getItem("hasSeenDiscountModal");

  //   if (isDevelopment || !hasSeenModal) {
  //     const timer = setTimeout(() => {
  //       setIsModalOpen(true);

  //       if (!isDevelopment) {
  //         localStorage.setItem("hasSeenDiscountModal", "true");
  //       }
  //     }, 1000);

  //     return () => clearTimeout(timer);
  //   }
  // }, []);

  // useEffect(() => {
  //   ScrollTrigger.create({
  //     trigger: ".hero", // Asegúrate de que tu sección Hero tenga esta clase
  //     start: "bottom bottom", // Inicio cuando el fondo del hero toca el fondo del viewport
  //     end: "+=100", // Pequeño buffer para iniciar el scroll
  //     onEnter: () => scrollToFeature(1),
  //   });
  // }, []);

  // ScrollTrigger.create({
  //   trigger: ".feature-position-2",
  //   start: "bottom bottom",
  //   end: "+=100",
  //   onEnter: () => scrollToFeature(3), // Función modificada para aceptar un parámetro de posición
  // });

  // const scrollToFeature = (position) => {
  //   const featureSection = document.querySelector(
  //     `.feature-position-${position}`
  //   );
  //   if (featureSection) {
  //     const featureTop = featureSection.offsetTop;
  //     window.scrollTo({
  //       top: featureTop,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  return (
    <div className="App">
      {/* <DiscountModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      /> */}
      <Navbar />
      <HeroSection className="hero" />
      <ProductsSection />
      <FeaturesSection />
      <SignUpSection />
      <FaqsSection />
      <WebFooter />
    </div>
  );
}

export default App;
