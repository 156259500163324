import React from "react";
import "./heroSection.css"; // Make sure the path to the CSS file is correct

const HeroSection = () => {
  return (
    <div className="hero">
      <div className="hero-right">
        <div className="hero-content">
          <h1 className="hero-heading">
            Embrace Your Digital Life with <br /> Clarity, Comfort, and Style
          </h1>
          <p className="hero-subtext">
            Beyond the Screen: A Vision of Health and Harmony.
          </p>
          <a
            data-umami-event="cta click"
            href="http://shop.bluckers.com"
            className="hero-cta"
          >
            <b>SHOP NOW</b>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
