import Feature from "./feature";

const FeaturesSection = () => (
  <div>
    <h2 className="section-title">Why Bluckers Are The Best</h2>

    <Feature
      title="UV and Blue Light Protection"
      position={1}
      description="Beyond just blocking blue light, Bluckers glasses shield your eyes from UV rays, offering comprehensive protection. Whether you're indoors facing screens or outdoors enjoying the sun, your eyes are guarded against the full spectrum of harmful light."
      imageUrl="/images/exploring.jpg" // Replace with your actual image path
      reverse={false}
    />
    <Feature
      title="Optimized Eye Comfort"
      position={2}
      description="Our lenses are engineered to reduce digital eye strain, allowing you to enjoy longer periods of screen time without discomfort. Perfect for professionals, gamers, and anyone in between, Bluckers glasses ensure your eyes remain refreshed and strain-free."
      imageUrl="/images/eye.png" // Replace with your actual image path
      reverse={true}
    />
    <Feature
      title="Enhanced Sleep Quality"
      position={3}
      description="Bluckers glasses block harmful blue light, promoting healthier sleep patterns. By wearing Bluckers in the evening, you're paving the way for a night of deeper, more restorative sleep, helping you wake up feeling rejuvenated and ready to tackle the day."
      imageUrl="/images/sleeping.png" // Replace with your actual image path
      reverse={false}
    />
    <Feature
      title="Modern Style Meets Functionality"
      position={4}
      description="Our collection is where modern style meets eye health. With a range of designs to suit any face shape and personal style, Bluckers glasses not only protect your eyes but also enhance your daily look. Make a statement with eyewear that reflects your individuality and commitment to wellness."
      imageUrl="/images/style.png" // Replace with your actual image path
      reverse={true}
    />
  </div>
);

export default FeaturesSection;
